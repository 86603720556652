import Vue from 'vue';

Vue.config.devtools = false

export default new Vue({
	el: '#header',
	data: {
		isOpen: false,
		dropLoans: false,
		dropUsefulStuff: false,
	},
	methods: {
		toggle: function() {
			this.isOpen = !this.isOpen
			document.body.classList.toggle('fix');
		},
		toggleLink: function() {
			this.isOpen = false;
			document.body.classList.remove('fix');
			this.dropLoans = false;
			this.dropUsefulStuff = false;
		},
		loansDropdown: function() {
			this.dropLoans = !this.dropLoans
		},
		usefulStuffDropdown: function() {
			this.dropUsefulStuff = !this.dropUsefulStuff
		},
	}
});
