import Vue from 'vue';
import VueSlider from 'vue-slider-component'

Vue.config.devtools = false
const numeral = require("numeral");

Vue.filter("formatCurrency", function(value) {
	return numeral(value).format("0,0.00");
});

Number.isInteger = Number.isInteger || function(value) {
	return typeof value === "number" &&
			isFinite(value) &&
			Math.floor(value) === value;
};

Vue.config.productionTip = false
if (document.querySelector('#calc')) {
	new Vue({
		el: '#calc',
		delimiters: ['${', '}'],

		components: {
			VueSlider
		},

		data: {
			defaultLoanTerm: 24,
			loanTerm: 0,
			loanValue: 2000,
			sliderValue: 0,
			prevLoanValue: 0,
			min: 500,
			max: 5000,
			increment: 50,
			buttonLink: "",
			enquiryUrl: "",
			queryStr: "",
			terms: [
				{months: 12},
				{months: 18, showCondition: {to: 950}},
				{months: 24},
				{months: 36, showCondition: {from: 1000}}
			],
			apr: 0.1,
			monthlyRepaymentAmount: 0,
			totalRepayableAmount: 0,
		},

		watch: {
			loanTerm: function(val) {
				this.calculate();
			},
			sliderValue: function(val) {
				this.loanValue = val;
				this.calculate();
			}
		},

        created(){
		    //SET A SLIDER DEFAULT
            this.sliderValue = this.min;
        },

		mounted() {
			if (this.$refs.apr) {
				this.apr = this.$refs.apr.value / 100;
			}

			if (this.$refs.enquiryUrl) {
				this.queryStr = window.location.search.replace('?', '');

				this.enquiryUrl = this.$refs.enquiryUrl.value;
			}

			if(this.$refs.defaultLoanValue){
			    let defaultLoanValue = parseInt(this.$refs.defaultLoanValue.value);
			    if(defaultLoanValue >= this.min && defaultLoanValue <= this.max){
			        this.loanValue = defaultLoanValue;
                }
            }

			if(this.$refs.defaultLoanTerm && this.$refs.defaultLoanTerm.value){
			    this.defaultLoanTerm = parseInt(this.$refs.defaultLoanTerm.value);
			    //CHECK RESTRICTIONS
                for(let i = 0; i < this.terms.length; i++){
                    let term = this.terms[i];
                    if(term.months === this.defaultLoanTerm && term.showCondition){
                        if(term.showCondition.to){
                            this.loanValue = this.min;
                        }else if(term.showCondition.from){
                            this.loanValue = term.showCondition.from;
                        }
                        break;
                    }
                }
            }

            this.loanTerm = this.defaultLoanTerm;
            this.sliderValue = this.loanValue;

			for (let ref in this.$refs) {
				this.$refs[ref].parentNode.removeChild(this.$refs[ref]);
			}

			this.calculate();
		},

		methods: {
			termIsAvailable(index) {
				let term = this.terms[index];
				if (term.showCondition) {
					if (term.showCondition.from && this.sliderValue < term.showCondition.from) {
						return false;
					}

					if (term.showCondition.to && this.sliderValue > term.showCondition.to) {
						return false;
					}
				}

				return true;
			},

			calculate() {
				if (!this.loanValue) {
					return;
				}

				if (!Number.isInteger(+this.loanValue)) {
					this.loanValue = this.prevLoanValue;
					return;
				}

				if (+this.loanValue < this.min || +this.loanValue > this.max) {
					this.loanValue = this.prevLoanValue;
					return;
				}

				this.loanValue = Math.round(this.loanValue / this.increment) * this.increment;

				this.prevLoanValue = this.loanValue;
				this.sliderValue = this.loanValue;

				//CHECK LOAN TERM IS STILL AVAILABLE
				for (let i = 0; i < this.terms.length; i++) {
					if (this.terms[i].months === this.loanTerm && !this.termIsAvailable(i)) {
						this.loanTerm = this.defaultLoanTerm;
						break;
					}
				}

				const monthlyInterestRate = Math.pow(1 + this.apr, 1 / 12) - 1;

				this.monthlyRepaymentAmount = +((monthlyInterestRate * this.loanValue * Math.pow((1 + monthlyInterestRate), this.loanTerm)) / (Math.pow(1 + monthlyInterestRate, this.loanTerm) - 1)).toFixed(2);
				this.totalRepayableAmount = +(this.monthlyRepaymentAmount * this.loanTerm).toFixed(2);
				this.buttonLink = this.enquiryUrl + "?LoanAmount=" + this.loanValue + "&LoanTermInMonths=" + this.loanTerm + "&" + this.queryStr;

				let buttons = document.querySelectorAll('.update-quote-link');
				for (let i = 0; i < buttons.length; i++) {
					buttons[i].href = this.enquiryUrl + "?LoanAmount=" + this.loanValue + "&LoanTermInMonths=" + this.loanTerm + "&" + this.queryStr;
				}
			}
		}
	});
}




