//import Reveal from './functions/scrollReveal';
import {scrollTo} from './functions/scrollTo';
//import CookieMessage from './components/cookieMessage'
import Accord from './components/accord'
import Calc from './components/calc'
import Navigation from './components/navigation';
import ImageHandling from './functions/imageHandling'
import copyClipboard from './components/copyClipboard'


// Default set-up
// document.querySelector('[data-scroll-trigger]').addEventListener('click', (e) => {
//     e.preventDefault();
//     scrollTo(document.querySelector(e.target.getAttribute("data-target")), 300);
// });

document.querySelector('#cookie-control').addEventListener('click', (e) => {
    e.preventDefault();
    CookieControl.open()
});